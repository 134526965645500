import React from 'react';
import akte from '../../../assets/images/verkoopsakte/verkoopsakte.jpg';
import rightPage from '../../../assets/images/verkoopsakte/verkoopsakte_right_page.jpg';
import Picture from "../../../../components/Picture/Picture";

const OldestHouse = () => {
    return <section className={'hdw-oldest-house-section hwt-section container'}>
        <div className={'hdw-content-wrapper'}>

            <figure className={''}>
                <div style={{overflow: 'hidden'}}>
                    <Picture SM={rightPage} MD={akte} alt={'Originele verkoopsakte van De Warande'}/>
                </div>
                <figcaption><i>1421 - registratie verkoopsakte van De Warande</i></figcaption>
                <figcaption><i>Te bezichtigen in het Leuvens stadsarchief: AL 7716 f° 259, 27-05-1421</i></figcaption>
            </figure>

            <div className={''}>
                <h2>De verkoopsakte</h2>
                <p>Dankzij de registratie van de authentieke verkoopsakte in het schepenboek, kunnen we met zekerheid zeggen dat De
                    Warande dateert van vóór 1421. Het gaat om de middelste paragraaf, op de tweede bladzijde. De
                    doorhalingen wijzen er op dat de verkoop effectief heeft plaatsgevonden.</p>
                <h3>Originele tekst</h3>
                <p>
                    It(em) Joh(ann)es d(i)ctus Holland(er)e contulit de se et suis succ(essoribus) Joh(ann)i Baten filio
                    mag(ist)ri Joh(ann)is Baten lapicide et suis succ(essoribus) domu(m) cu(m) suis p(er)tr(inentibus)
                    nu(n)cupat(um) Warande / p(ro)ut sit(um) est in Castristrata int(er) bona Joh(ann)is Vullinc et
                    bo(na) Henrici de Artslo d(ic)ti de Cauwelille pistor(is) extenden(tem) retrosu(m) ad Dylia(m)
                    he(re)d(itarie) tenen(dum) et possiden(dum) sub iure d(omi)ni fu(n)di to(ta)li (???) p(rius) inde
                    de(bi)to videl(icet) uno capone et sex denarii antiq(ui) census ac uno floreno Renen(si) aur(eo)
                    bono et legal(i) a(n)nui (???) et h(er)ed(itarie) ce(n)sus necno(n) quinq(ua)gintauna libr(os)
                    an(n)ui ce(n)sus mo(ne)te q(uo)l(ibet) t(erm)i(n)o soluc(ionis) (communi)ter in b(ur)sa
                    c(ur)re(n)t(is) t(ermin)is de(bi)t(is) et (con)suet(is) p(er)sol(vendos). Et p(rae)t(er)ea sub uno
                    floreno Hollan(die) aur(eo) bono et legali a(n)nui et h(er)ed(tarii) ce(n)sus et reddit(us) singulis
                    annis ( ?) ad nat(a)le d(omi)ni p(er)sol(vendo) in fut(uru)um p(ri)maq(ue) soluc(i)one d(ic)ti
                    flor(eni) Hollan(die) h(er)ed(itarie) ce(n)sus inchoanda ad p(ri)mu(m) festu(m) nat(a)lis d(omi)ni
                    q(uo)d erat [fout voor erit] post morte(m) d(ic)ti Joh(ann)is Holland(er)e et non(n) p(ri)us. Et sub
                    h(oc) an(n)uo et h(er)ed(itarie) ce(n)su / et redditu ac jure d(omi)ni fundi p(re)scr(ri)pt(is)
                    p(ro)misit d(ic)tus Joh(ann)es Holland(er)e p(re)fato / Joh(ann)i Bate(n) et suis succ(essoribus)
                    h(ae)c eade(m) bona p(rae)sc(ri)pta ab o(mn)i alio impe(dimen)to et cal(engia) ult(er)i(us)
                    war(randiza)re et sp(ecialiter) ??? satisfac(er)e Juede Willemair Maii xxvij
                </p>
                <h3>Vertaling</h3>
                <p>
                    Jan genaamd Hollandere draagt aan Jan Baten, zoon van steenhouwer meester Jan Baten, een huis
                    met toebehoren, genaamd De Warande, over, zoals het gelegen is in de Borchstraat, tussen de
                    goederen van Jan Vullinc en de goederen van Hendrik van Artslo, genaamd van Cauwelille, bakker, en
                    zoals het zich naar achteren uitstrekt tot aan de Dijle, mits betaling van het voorgaande recht van
                    de
                    grondheer, namelijk een kapoen en zes denieren oude cijns, een gouden Rijnsgulden jaarlijkse cijns,
                    en 51 ponden jaarlijkse cijns te betalen in de munt die op dat ogenblik in omloop zal zijn. En daar
                    bovenop voor een jaarlijkse cijns van een gouden Hollandse florijn, telkenjare in de toekomst te
                    betalen met Kerstmis, waarbij de eerste betaling moet starten met Kerstmis na de dood van
                    genoemde Jan Hollandere en niet eerder. En onder deze voorwaarden draagt genoemde Jan
                    Hollandere genoemd goed over aan Jan Baten en zijn erfgenamen, vrij en onbelast, in bijzijn van de
                    schepenen Juede en Willemar, 27 mei.
                </p>
            </div>
        </div>
    </section>
};

export default OldestHouse;
