import React from 'react';
import {useHistory} from "react-router-dom";

const GoBackSection = () => {

    const history = useHistory();

    return <section className={'hdw-go-back-section hwt-section container'}>
        <div style={{position: 'relative'}}>
            <button className={'btn-anchor-style'} type="button" onClick={() => history.goBack()}>
                <span aria-label={'Emoji of Index finger pointing to the right'} role={'img'}>👉</span> Home
            </button>
        </div>
    </section>
}

export default GoBackSection;