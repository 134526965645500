import React from 'react';
import Wrapper from "../../Containers/Wrapper";
import {getCorrectlySpacedString} from "../../../utils/componentTyping.util";
import PropTypes from "prop-types";
import TYPES from "../../../CONSTANTS/TYPES";

const FullScreen = ({children, extraClassName}) => {

    const newExtraClassName = getCorrectlySpacedString('gc-full-screen-section', extraClassName);

    return <Wrapper {...{
        type: TYPES.POSITIONED.RELATIVE.COVERED.FULL_SCREEN,
        extraClassName: newExtraClassName,
        tag: 'section'
    }}>
        {children}
    </Wrapper>
};

FullScreen.propTypes = {
    extraClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.node.isRequired
    ])
};

export default FullScreen;
