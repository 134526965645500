import {useEffect} from 'react';

const ScrollToTopOnMount = () => {

    const currentRoute = window.location.pathname;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentRoute]);

    return null;
};

export default ScrollToTopOnMount;
