import React from 'react';
import {getCorrectlySpacedString} from "../../utils/componentTyping.util";
import Wrapper from "../Containers/Wrapper";
import PropTypes from "prop-types";
import TYPES from "../../CONSTANTS/TYPES";


const Section = ({children, extraClassName, tag, height}) => {

    extraClassName = getCorrectlySpacedString('gc-page-section', extraClassName);

    return <Wrapper {...{
        type: TYPES.COVERED.COVERED_WIDTH,
        extraClassName,
        tag,
        height
    }}>
        {children}
    </Wrapper>
};

Section.propTypes = {
    extraClassName: PropTypes.string,
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.node.isRequired
    ]),
    tag: PropTypes.string
};

Section.defaultProps = {
    tag: 'section'
};

export default Section;
