import _ from "lodash";
import Picture from "../../Picture/Picture";
import React, {useRef} from "react";
import {getCorrectlySpacedString} from "../../../utils/componentTyping.util";

const Article = ({article, displayImgs, extraClassName, children}) => {


    const {figure, content, title, subTitle} = article;
    const {src, figCaption, alt, figureType} = _.pick(figure, ['src', 'figCaption', 'figureType', 'figureType']);

    const articleClassName = getCorrectlySpacedString(extraClassName, 'gc-article');

    const articleRef = useRef(null);

    return <article ref={articleRef} {...{className: articleClassName}}>

        {title ? <h3>{title}</h3> : null}
        {subTitle ? <h4>{subTitle}</h4> : null}

        {src && displayImgs ? <figure>
            <Picture {...{
                src,
                type: figureType,
                alt,
                extraClassName: `${extraClassName}-img`
            }}/>
            {figCaption ? <figcaption>{figCaption}</figcaption> : null}
        </figure> : null}

        {!_.isEmpty(content) ?
            <div dangerouslySetInnerHTML={{__html: content}}/> : null}
        {children}
    </article>;
};

export default Article;
