import React, {Suspense, useEffect, useState} from 'react';

const CurrentStateCarousel = React.lazy(() => import("./CurrentStateCarousel"));

const CurrentState = ({isInViewPort, domRef}) => {
    const [shouldBeVisible, setShouldBeVisible] = useState(false);

    useEffect(() => {
        if (isInViewPort) setShouldBeVisible(true)
    }, [isInViewPort]);

    return <>
        <section ref={domRef} className={'hdw-current-state-section hwt-section container'}>
            <div className={'row'}>
                <div className={'col-12 col-lg-4'}>
                    <h2>Tegenwoordig</h2>
                    <p>
                        Het huis floreert nu als nooit tevoren en wordt gekenmerkt door zijn warme, gezellige vertrekken
                        waarin het aangenaam toeven is. Het haardvuur in de voorkamer en woonkamer, de houten moerbalken
                        in het plafond en de waterput op de binnenplaats zijn niet te onderschatten
                        troeven waaraan De Warande zijn karaktervolle uitstraling dankt. Neem op open
                        monumentendag - als De Warande opengesteld is - gerust een kijkje en oordeel zelf.
                    </p>
                </div>
                <div className={'col-12 col-lg-8'}>
                    {shouldBeVisible ? <Suspense
                        fallback={<div height={{minHeight: '571px', backgroundColor: 'purple'}}>Loading...</div>}>
                        <CurrentStateCarousel/>
                    </Suspense> : null}
                </div>
            </div>

        </section>
    </>
};

export default CurrentState;
