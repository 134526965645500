import CONSTANTS from "./CONSTANTS";

const coveredCssClasses = ['gc-covered'];
const positionedCssClasses = ['gc-positioned'];
const positionedAbsoluteCssClasses = [...positionedCssClasses, 'gc-positioned-absolute'];
const positionedRelativeCssClasses = [...positionedCssClasses, 'gc-positioned-relative'];
const positionedRelativeCoveredCssClasses = [...positionedRelativeCssClasses, 'gc-covered'];
const shapedCssClasses = ['gc-shaped'];

const POSITIONED = {
    [CONSTANTS.CSS_CLASSES_KEY]: positionedCssClasses,

    get ABSOLUTE() {
        return {
            [CONSTANTS.CSS_CLASSES_KEY]: positionedAbsoluteCssClasses,
            CENTERED: {
                [CONSTANTS.CSS_CLASSES_KEY]: [...positionedAbsoluteCssClasses, 'positioned-centered'],
            },
            HORIZONTALLY_CENTERED: {
                [CONSTANTS.CSS_CLASSES_KEY]: [...positionedAbsoluteCssClasses, 'positioned-horizontally-centered'],
            },
            VERTICALLY_CENTERED: {
                [CONSTANTS.CSS_CLASSES_KEY]: [...positionedAbsoluteCssClasses, 'positioned-vertically-centered'],
            },
        }
    },
    RELATIVE: {
        [CONSTANTS.CSS_CLASSES_KEY]: positionedRelativeCssClasses,
        COVERED: {
            [CONSTANTS.CSS_CLASSES_KEY]: positionedRelativeCoveredCssClasses,
            FULL_SCREEN: {
                [CONSTANTS.CSS_CLASSES_KEY]: [...positionedRelativeCoveredCssClasses, 'gc-covered-full-screen'],
            },
            COVERED_WIDTH_AND_HEIGHT: {
                [CONSTANTS.CSS_CLASSES_KEY]: [...positionedRelativeCoveredCssClasses, 'gc-covered-width-and-height']
            }
        },

    },
};

const COVERED = {
    [CONSTANTS.CSS_CLASSES_KEY]: coveredCssClasses,
    COVERED_HEIGHT: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...coveredCssClasses, 'gc-covered-height']
    },
    COVERED_WIDTH: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...coveredCssClasses, 'gc-covered-width']
    },
    COVERED_WIDTH_AND_HEIGHT: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...coveredCssClasses, 'gc-covered-width-and-height']
    }
};

const SHAPED = {
    [CONSTANTS.CSS_CLASSES_KEY]:
    shapedCssClasses,
    CIRCLE: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...shapedCssClasses, 'gc-circle-shaped']
    },
    CLIPPED_TEXT_BALLOON: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...shapedCssClasses, 'gc-text-balloon-shaped']
    },
    CLIPPED_CANVAS: {
        [CONSTANTS.CSS_CLASSES_KEY]: [...shapedCssClasses, 'gc-canvas-shaped']
    }
};

export default {
    POSITIONED,
    SHAPED,
    COVERED
};
