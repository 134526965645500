class article {
    constructor(title, subTitle, content, figure) {
        this.title = title;
        this.subTitle = subTitle;
        this.figure = figure;
        this.content = content;   //TODO: make model
    }
}

export default article;
