import React, {Suspense, useEffect, useState} from 'react';
import {Link} from "react-router-dom";

const RestauratieCarousel = React.lazy(() => import("./RestauratieCarousel"));


const Restauratie = ({isInViewPort, domRef}) => {

    const [shouldBeVisible, setShouldBeVisible] = useState(false);

    useEffect(() => {
        if (isInViewPort) setShouldBeVisible(true)
    }, [isInViewPort]);

    return <section ref={domRef} id={'hdw-restauratie'} className={'hdw-restoration-section hwt-section container'}>
        <div className={'row'}>
            {
                shouldBeVisible ?
                    <Suspense fallback={<div height={{minHeight: '571px', backgroundColor: 'purple'}}>Loading...</div>}>
                        <div className={'col-12 col-lg-8'}>
                            <RestauratieCarousel/>
                        </div>
                    </Suspense> : null
            }
            <div className={'col-12 col-lg-4'}>
                <h2>Restauratie</h2>
                <p>
                    Uiteraard heeft De Warande heel wat verbouwingen gekend – in de 17de eeuw kreeg het huis
                    bijvoorbeeld een nieuwe ‘barokke’ gevel. Het huis verdween bijna geruisloos – het lot van veel van
                    ons erfgoed – tot het in de jaren ’90 van de vorige eeuw een eerste reddingsoperatie onderging, om
                    in de jaren 2004-2005 grondig gerestaureerd te worden.</p>

                <p>Dat dit een proces van lange adem was, wordt geïllustreerd door volgende archiefbeelden van het
                    voorhuis en van het inmiddels grotendeels verdwenen achterhuis aan de Dijle</p>

                <Link to={'/restauratie'}>Meer weten over de restauratie</Link>
            </div>
        </div>
    </section>
};

export default Restauratie;
