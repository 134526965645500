import React, {useState, useRef, useEffect} from "react";
import WarandeTimeLine from "../../partials/TimeLine/WarandeTimeLine";
import HistoryPartial from "../../partials/history/HistoryPartial";
import {containedValue} from "../../../../utils/Numbers.util";

const HistorySection = () => {

    const [percentageRead, setPercentageRead] = useState(0);
    const [maxHeight, setMaxHeight] = useState(null);

    const historicPartialRef = useRef(null);
    const handleScroll = e => {
        const scrollPositionTop = window.scrollY;

        const historicPartialoffsetTop = historicPartialRef?.current?.offsetTop;
        const historicPartialScrollheight = historicPartialRef?.current?.scrollHeight;

        const rectifiedValue = containedValue(scrollPositionTop, historicPartialoffsetTop, historicPartialoffsetTop + historicPartialScrollheight);
        const percentageRead = 100 * (rectifiedValue - historicPartialoffsetTop) / (historicPartialScrollheight - window.innerHeight);

        setPercentageRead(percentageRead);
    };
    const handleResize = e => {
        const maxHeight = historicPartialRef?.current?.scrollHeight;
        setMaxHeight(maxHeight);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
    });


    return <section className='gc-history-section container'>
        <WarandeTimeLine {...{percentageRead, maxHeight}}/>
        <HistoryPartial {...{historicPartialRef}}/>
    </section>
};

export default HistorySection
