import CONSTANTS from "./CONSTANTS";

export const getComposedType = function (...args) {

	if (args.length === 1) {
		return args[0];
	}

	const cssClasses = args.reduce((reduced, current) => [...current[CONSTANTS.CSS_CLASSES_KEY], ...reduced], []);

	return {
		[CONSTANTS.CSS_CLASSES_KEY]: cssClasses
	}
};