import React, {useState, useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import {getCorrectlySpacedString} from '../../utils/componentTyping.util';
import _ from 'lodash';
import CONSTANTS from "../../CONSTANTS/CONSTANTS";
import {PICTURE_TYPES} from "./PICTURE_TYPES";

export const Picture = ({XS, SM, MD, LG, XL, src, alt, extraClassName, type}) => {


    const [dimensions, setDimensions] = useState({});
    const {initialWidth, initialHeight, currentWidth, currentHeight} = dimensions;
    const imgRef = useRef(null);

    const setImageDimensions = () => {

        const imgNode = imgRef.current;

        const initialWidth = imgNode.naturalWidth;
        const initialHeight = imgNode.naturalHeight;

        const currentWidth = imgNode.width;
        const currentHeight = imgNode.height;

        const newDimensions = {
            initialWidth,
            initialHeight,
            imgRatio: initialWidth / initialHeight,
            currentWidth,
            currentHeight,
            currentRatio: currentWidth / currentHeight
        };

        setDimensions(prev => {
            if (_.isEmpty(prev)) {
                prev = newDimensions;
            }
            return prev;
        })
    };

    useEffect(() => {
        if (currentWidth > initialWidth || currentHeight > initialHeight) {
            console.error('Img quality reduction due to oversizing.');
        }
    }, [initialWidth, initialHeight, currentWidth, currentHeight]);

    const className = getCorrectlySpacedString('gc-picture', ..._.get(type, CONSTANTS.CSS_CLASSES_KEY, []), extraClassName);

    return <picture>

        {XL ? <source media={`(min-width: ${CONSTANTS.BREAKPOINTS.BOOTSTRAP_BREAKPOINTS.LG.MAX_WIDTH + 1}px)`}
                      srcSet={XL}/> : null}
        {LG ? <source media={`(min-width: ${CONSTANTS.BREAKPOINTS.BOOTSTRAP_BREAKPOINTS.MD.MAX_WIDTH + 1}px)`}
                      srcSet={LG}/> : null}
        {MD ? <source media={`(min-width: ${CONSTANTS.BREAKPOINTS.BOOTSTRAP_BREAKPOINTS.SM.MAX_WIDTH + 1}px)`}
                      srcSet={MD}/> : null}
        {SM ? <source media={`(min-width: ${CONSTANTS.BREAKPOINTS.BOOTSTRAP_BREAKPOINTS.XS.MAX_WIDTH + 1}px)`}
                      srcSet={SM}/> : null}
        {XS ? <source media={`(min-width: 0)`} srcSet={XS}/> : null}


        <img {...{
            onLoad: setImageDimensions,
            className,
            src: XS || SM || MD || LG || XL || src,
            alt: alt || '', ref: imgRef
        }}/>
    </picture>;
};

Picture.propTypes = {
    XS: PropTypes.string,
    SM: PropTypes.string,
    MD: PropTypes.string,
    LG: PropTypes.string,
    alt: PropTypes.string,
    type: PropTypes.shape({
        [CONSTANTS.CSS_CLASSES_KEY]: PropTypes.arrayOf(PropTypes.string)
    }),
};

Picture.defaultProps = {
    type: PICTURE_TYPES.KEEP_DIMENSIONS_NO_OVERFLOW
};
export default Picture;
