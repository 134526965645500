import React from 'react';
import Picture from "../../../../components/Picture/Picture";
import {getComposedType} from "../../../../CONSTANTS/types.util";
import warandeImg from "../../../assets/images/welcomeBanner/welcome_banner.jpeg";
import warandeImgSmall from "../../../assets/images/welcomeBanner/welcome_banner_small.jpeg";
import HomePageTitle from "./HomePageTitle";
import FullScreen from "../../../../components/Sections/FullScreen/FullScreen";
import {PICTURE_TYPES} from "../../../../components/Picture/PICTURE_TYPES";

const WelcomeSection = () => {
    const {CENTER: centerCrop} = PICTURE_TYPES.CROPPING;
    return <FullScreen {...{extraClassName: 'gc-welcome-section'}}>
        <Picture {...{
            type: getComposedType(centerCrop),
            SM: warandeImgSmall,
            MD: warandeImg,
            alt: 'Foto van de voorgevel van De Warande',
            extraClassName: 'warande-img-front'
        }}/>
        <HomePageTitle/>
    </FullScreen>;
}

export default WelcomeSection;
