import React from 'react';
import {getCorrectlySpacedString} from "../../utils/componentTyping.util";
import _ from "lodash";
import PropTypes from "prop-types";
import CONSTANTS from "../../CONSTANTS/CONSTANTS";

const Wrapper = ({extraClassName, children, type, tag, passedRef, height, maxHeight}) => {

    const className = getCorrectlySpacedString('gc-wrapper', ..._.get(type, CONSTANTS.CSS_CLASSES_KEY, []), extraClassName);   //TODO: this kind of code lines should be dry-'ed up

    const Tag = tag || 'div';   //TODO you can pass tag: 'blobbers' and you'd get <blobbers/> :s

    return <Tag ref={passedRef} className={className}
                style={height ? {height: `${height}px`} : maxHeight ? {maxHeight: `${maxHeight}px`} : {}}>
        {children}
    </Tag>
};


Wrapper.propTypes = {
    extraClassName: PropTypes.string.isRequired,
    type: PropTypes.shape({
        [CONSTANTS.CSS_CLASSES_KEY]: PropTypes.arrayOf(PropTypes.string)
    }),
    children: PropTypes.oneOfType([
        PropTypes.string.isRequired,
        PropTypes.object.isRequired,
        PropTypes.node.isRequired
    ]).isRequired,
    tag: PropTypes.string
};


/*Wrapper.defaultProps = {
	type: TYPES.COVERED.COVERED_WIDTH_AND_HEIGHT
};*/

export default Wrapper;
