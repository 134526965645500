import React from 'react';

const ExtendedRestoration = () => {
    return <section className={'hdw-restoration-section hwt-section container'}>
        <div style={{position: 'relative'}}>
            <h1>Restauratie</h1>
            <div>
                <h2>Jaren 80</h2>
                <p>
                    ‘De Warande’ was er eind jaren ’80 erg aan toe. Afbraak dreigde.
                    “De bouwfysische toestand”, zo stond nog en het advies van het toenmalige Bestuur van Monumenten en
                    Landschappen op basis waarvan het huis het statuut van beschermd monument kreeg, “is ingevolge
                    leegstand
                    en
                    gebrek aan onderhoud ermbarmelijk”.</p>
            </div>

            <div>
                <h2>Redding</h2>
                <div>
                    <h3>Paul Vandenbroeck</h3>
                    <p>
                        Een nieuwe eigenaar, kunsthistoricus Paul Vandenbroeck, liet een aantal dringende
                        herstellingswerkzaamheden uitvoeren:
                        <ul>
                            <li>Het dak werd vernieuwd,</li>
                            <li>de verdwenen top van de achtergevel werd herbouwd,</li>
                            <li>de gevel voor- en achteraan gestabiliseerd en verankerd.</li>
                            <li>Grote gaten in de keldermuren werden gedicht.</li>
                            <li>De zolderverdieping kreeg een nieuwe eiken vloer en dito schrijnwerk.</li>
                            <li>De zolder werd een sober ingerichte ‘loft’, met een klein keukentje en een
                                badkamertje.
                            </li>
                            <li>Vandenbroeck haalde op de eerste en op de gelijkvloerse verdieping oude, versleten
                                vloeren
                                en
                                tussenschotten weg.
                            </li>
                        </ul>
                    </p>
                    <p>‘De Warande’ werd door toedoen van Vandenbroeck op 2 oktober 1989 op het voorontwerp van lijst
                        van
                        voor
                        bescherming vatbare monumenten geplaatst. Op 22 maart 1991 werd het huis door toenmalig minister
                        Louis
                        Waltniel als monument beschermd, “om reden van artistieke en historische waarde’.
                    </p>
                </div>
                <div>
                    <h3>Elisabeth Houben & Axel Buyse</h3>
                    <p>Op 23 augustus 1999 kwam ‘De Warande’ in het bezit van Elisabeth Houben en Axel Buyse, met de
                        bedoeling om
                        het nog grotendeels bouwvallige huis verder te restaureren en er hun woonhuis van te maken. Dat
                        ging
                        niet
                        zonder slag noch stoot – bij de opstart van de restauratie, begin 2004 die gebeurde met
                        subsidies
                        van de
                        Vlaamse Overheid, de provincie Vlaams-Brabant en de stad Leuven, strooide de fiscus roet in het
                        eten
                        met een
                        forse claim omdat het huis niet tijdig zou bewoond zijn. Via bizarre redeneringen kreeg de
                        fiscus
                        bovendien
                        gelijk van de rechtbank – niet tijdig je bestelde elektrische keukenapparaten in huis krijgen
                        gold
                        als
                        ‘overmacht’ – een aanslepende restauratie van een beschermd monument, met alle nodige
                        voorstudies,
                        is dat
                        niet!
                    </p>
                    <p>
                        De restauratie van ‘De Warande’, die volgens alle regels van de kunst gebeurde onder toezicht
                        van
                        ir. Piet
                        Stevens, werd grotendeels voltooid in 2006. In een latere fase moesten nog problemen met de
                        dakgoten
                        en met
                        regenwater dat bij felle wind door de glasramen van de achtergevel sijpelde, opgelost worden.
                        Dat
                        gold ook
                        voor vochtproblemen aan de kant van een aangrenzend studentenhuis. Intussen is ‘De Warande’ weer
                        helemaal
                        ‘ingeleefd’. Het huis kreeg kort na de restauratie de Monumentenprijs van de provincie
                        Vlaams-Brabant en het
                        eindigde als tweede voor de Vlaamse Monumentenprijs.
                    </p>
                    <p>
                        De strenge isolatienormen van de jongste jaren vormen het belangrijkste probleem voor het verder
                        beheer van
                        de woning. Ten tijde van de restauratie mocht er geen dubbel glas gebruikt worden. Een
                        overschakeling nu zou
                        tot hoge kosten leiden en volgens experts van Erfgoed bestaat de kans dat de muren bij een te
                        sterke
                        isolatie zouden beginnen ‘zweten’ door de zouten die er zich in al die eeuwen hebben opgehoopt.
                        De
                        stookkosten kunnen voorlopig evenwel perfect onder controle gehouden worden door middel van
                        zware
                        gordijnen
                        en in geval van vriesweer het bijstoken met hout.
                    </p>
                </div>
            </div>

            <div>
                <h2>Huidige staat</h2>
                <p>Het huis heeft tegenwoordig een barokke voorgevel. Die dateert luidens een ‘cartouche’ op die gevel
                    uit
                    1664. Daarvoor had ‘De Warande’ naar alle waarschijnlijkheid een laatmiddeleeuwse trapgevel en nog
                    daarvoor een houten voorgevel. Opmerkelijk aan het ‘voorhuis’ is het feit dat het structureel in
                    verregaande mate intact is gebleven. De indeling is in de jongste eeuwen niet meer ingrijpend
                    gewijzigd.
                    Een van de grootste recente ingrepen was de verwijdering, in 1843, van de kruisvenster op de eerste
                    verdieping. Delen van die vroegere vensterconstructie zijn in de kelder teruggevonden.</p>
                <p>De zolderverdieping is opengewerkt met twee ovaalronde ‘oculi’ in de achtergevel. De dakconstructie
                    met
                    genummerde spanten en een grote windas is bewaard gebleven. In de top van de voorgevel bevindt zich
                    een
                    natuurstenen laadvenster. De gevel werd eind 2000 aan een uitgebreide, gedetailleerde
                    materieel-technisch onderzoek onderworpen door Johan Grootaers van A.M. Consult.</p>
                <p>Het beschermingsadvies beschrijft de voorgevel van het huis als een vier traveeën breed barok
                    dwarshuis
                    met twee bouwlagen … “opgetrokken in bak- en natuursteen, met natuurstenen gelijkvloers en
                    verdieping.
                    Geheel opengewerkte gelijkvloerse verdieping, opgevat als een winkelpui met drie rechthoekige
                    vensters
                    met doorlopende druiplijst waarboven een rij kleinere vensters waarvan de lateien zijn uitgewerkt
                    als
                    een geprofileerde puilijst. Barokke rondboogdeur met van blokwerk voorziene posten en zware
                    festoenen in
                    de zwikken. Gehistoriseerde trapeziumvormige sluitsteen, een hert in een warande of gesloten
                    jachtterrein voorstellend”.</p>
                <p>Het beschermadvies zegt nog: “Op de verdieping vier hoge rechthoekige vensters waarvan de lateien
                    zijn
                    doorgetrokken en die aldus een vooruitspringende druiplijst vormen. Gedeeltelijk bewaarde, eveneens
                    okergele beschildering van het muurvlak tussen de vensters van het gelijkvloers en de verdieping,
                    met
                    het bijna onleesbare opschrift In De Warande Estaminet. In het midden van de verdieping een
                    bas-reliëf
                    met een putto op een wijnton, een duidelijke verwijzing naar de vroegere bestemmen [brouwerij en
                    drankwinkel]. Bekroning met een topgevel die het midden houdt tussen een trapgevel en een halsgevel
                    met
                    voluten.”</p>
                <p>Ing. Grootaers ging er bij zijn onderzoek van uit dat de gesmede muurankers die de zolderbalklaag in
                    de
                    voorgevel verzekeren, gerecupereerd zijn van de laatmiddeleeuwse gevel. De witstenen
                    kruisvensterpartij
                    van de bovenverdieping, die in 1842 verdween, zou volgens hem ook uit een vroegere periode hebben
                    gestamd. Binnen in de woning vond de expert oorspronkelijk laatmiddeleeuws metselwerk, met
                    kepervormige
                    wandnissen uit diezelfde periode. Ook de achterwand van de voorkamer op de gelijkvloerse etage
                    behoort
                    tot de oudste bouwfase van het voorhuis.</p>
                <p>Wat de voorgevel betreft, heeft Grootaers het over “een uitzonderlijke documentwaarde” voor de
                    Brabantse
                    bak- en zandsteenarchitectuur in de 17e eeuw in het hele hertogdom Brabant”. Hij verwijst naar de
                    oorspronkelijke afwerking van de bakstenen delen van de gevel met een bont ‘alta rosa’. Een bewijs
                    volgens hem dat het decoratief inschilderen en dagstrepen van parement niet enkel opgang maakte in
                    de
                    18e eeuw, maar al eerder.</p>
                <p>De huisscheidingsmuren op de gelijkvloerse en eerste verdieping waren in de 17e eeuw voorzien van een
                    eenlagige kalkzandhaarbepleistering. Een muurbehandeling die samen moet bekeken worden met de alleen
                    nog
                    in de gelijkvloerse achterkamer – tegenwoordig keuken – aanwezige ‘geturkte’, gewelfde plafonds. Het
                    interieur werd regelmatig gekalkt, eerste overwegend wit, later meer omber- en blauwwit, aldus
                    Grootaers.</p>
            </div>
        </div>
    </section>
};

export default ExtendedRestoration;