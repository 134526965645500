import React from 'react';
import {getCorrectlySpacedString} from "../../utils/componentTyping.util";
import _ from "lodash";
import CONSTANTS from "../../CONSTANTS/CONSTANTS";
import Section from "../Sections/Sections";
import Picture from "../Picture/Picture";
import Article from "../Sections/TextAndImage/Article";
import Wrapper from "../Containers/Wrapper";

const TimeLine = ({extraClassName, type, historicPaper, passedRef, maxHeight}) => {
    const className = getCorrectlySpacedString(_.get(type, CONSTANTS.CSS_CLASSES_KEY, []), extraClassName);
    let {title, subTitle, description, articles} = historicPaper;

    articles = articles?.sort((articleA, articleB) => articleA.date < articleB.date ? -1 : 1);

    return historicPaper ?
        <Wrapper {...{extraClassName: getCorrectlySpacedString('gc-time-line', className), passedRef, maxHeight}}>

            {title || subTitle || description ?
                <header className={getCorrectlySpacedString('gc-historic-article-title-part')}>
                    {title ? <h2 className={getCorrectlySpacedString('gc-historic-article-title')}>{title}</h2> : null}
                    {subTitle ?
                        <h3 className={getCorrectlySpacedString('gc-historic-article-subtitle')}>{subTitle}</h3> : null}
                    {description ?
                        <p className={getCorrectlySpacedString('gc-historic-article-description')}>{description}</p> : null}
                </header> : null}

            {!_.isEmpty(articles) ? articles.map((historicArticle, index) => {
                const {figure} = historicArticle;

                return !_.isEmpty(historicArticle) ? <Article key={`article-${index + 1}`} {...{
                    article: historicArticle,
                    extraClassName: getCorrectlySpacedString('gc-historic-chapter-part'),
                    displayImgs: false
                }}>
                    <Section key={`historic-article-${index + 1}`} {...{
                        extraClassName: getCorrectlySpacedString('gc-time-line-subsection', ..._.get(type, CONSTANTS.CSS_CLASSES_KEY, []))
                    }}>

                        <span className={getCorrectlySpacedString('gc-vertical-seperator')}></span>

                        {figure ? <Section  {...{extraClassName: getCorrectlySpacedString('gc-img-part')}}>
                            <Picture {...{...figure, extraClassName: 'hdw-icon'}}/>
                        </Section> : null}
                    </Section>
                </Article> : null
            }) : null}
        </Wrapper> : null;
};

export default TimeLine;
