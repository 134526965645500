import React from 'react';

const Credits = () => {
    return <section className={'hdw-credits--section hwt-section container'}>
        <div style={{position: 'relative'}}>

            <h2>Met dank aan</h2>
            <ul>
                <li>dr. Marc Nelissen (archivaris - KULeuven, vertaler schepenboek 1421)</li>
                <li>Prof. em. Mark Derez</li>
                <li>dr. Johan Grootaers</li>
                <li>Prof. em. Ing. Piet Stevens</li>
                <li>Prof. dr. Paul Van Den Brouck</li>
                <li>Het agenstschap Onroerend Erfgoed</li>
                <li>Stad Leuven</li>
                <li>Thomas Opdebeeck - website</li>
                <li>Simon Opdebeeck - Consultant</li>
            </ul>

            <p>Voor bijkomende informatie: axel.buyse@gmail.com & 0492 13 07 02</p>
        </div>
    </section>;
};

export default Credits;
