import React from 'react';

import PropagandaImg from '../../../assets/images/naziPropaganda/propaganda.jpeg';
import Picture from "../../../../components/Picture/Picture";

const DuitsePropaganda = () => {
    return <section className={'hdw-nazi-propaganda hwt-section container'}>
        <div className={'row'}>
            <div className={'col-12 col-lg-6'}>
                <h2>Propaganda</h2>
                <p>
                    Op 28 juni 1914 joeg de jonge Servische nationalist Gavrilo Princip in Sarajevo de aartshertog van
                    Oostenrijk-Hongarije en diens echtgenote een kogel door het hoofd, en stak daarmee de lont aan het
                    kruitvat dat Europa toen was. De Eerste Wereldoorlog begon, en binnen enkele maanden werd België
                    bezet door het Duits leger. Op 20 augustus werd
                    Leuven ingenomen. Aanvankelijk verliep de bezetting
                    rustig, maar amper een week na de inname barstte de bom. Hoe het juist begon
                    is niet duidelijk. Volgens de Duitsers waren de
                    burgers zelf beginnen schieten, vanuit de overtuiging dat het Belgische leger hen
                    vanuit de vesting Antwerpen zou komen ontzetten. Behalve eenzijdige Duitse getuigenissen is hiervoor
                    echter geen bewijs.
                    Volgens Belgische ooggetuigen begon het toen Duitse cavaleriesoldaten het slagveld bij Buken
                    verlieten, en naar Leuven kwamen, waar de ingekwartierde soldaten ze aanzagen voor een aanvallend
                    leger, en dus begonnen te schieten. Toen de vergissing duidelijk werd, kregen de bewoners van
                    nabijgelegen huizen de schuld.</p>
                <p>
                    Het gevolg van deze schermutselingen in Leuven was dat vanaf de 25e tot en met de 29e augustus
                    een groot deel van de stad moedwillig werd platgebrand. De universiteitsbibliotheek, die toen nog in
                    de universiteitshal tussen de Naamsestraat en
                    de Oude markt gevestigd was,
                    werd verwoest. Ook het dak van de Sint-Pieterskerk ging eraan. Honderden burgers werden
                    standrechterlijk terechtgesteld, duizenden moesten de stad
                    ontvluchten, en nog eens duizenden werden naar
                    Duitse kampen gestuurd. Waarschijnlijk diende dit buitenproportionele optreden van de Duitsers ook
                    om angst te
                    zaaien, en zo verdere opstanden onder meer in Brussel te ontmoedigen. De geallieerde propaganda
                    speelde meteen in op de Duitse wreedheden.

                    Mede daarom, maar zeker ook uit verontwaardiging over de slechte staat van veel Belgisch
                    bouwkundig erfgoed, fotografeerden de Duitse troepen op grote schaal in de daarop volgende
                    oorlogsjaren gebouwen die de vijandelijkheden
                    hadden overleefd; daaronder ook De Warande. Als u goed kijkt kunt u in de
                    weerspiegeling nog een schim van de Duitse militaire fotograaf zien.</p>

            </div>

            <figure className={'col-12 col-lg-6'}>
                <Picture src={PropagandaImg} alt={'Duitse officier die de voordeur van De Warande fotografeert'}/>
                <figcaption>Eerste Wereldoorlog - Duitse officier die voordeur van De Warande fotografeert.</figcaption>
            </figure>
        </div>

    </section>
};

export default DuitsePropaganda;
