import React, {useEffect, useRef} from 'react';
import _db from "./mockedDB";
import TimeLine from "../../../../components/TimeLine/TimeLine";

const WarandeTimeLine = ({percentageRead, maxHeight}) => {

    const timeLineRef = useRef(null);

    useEffect(() => {
        const scrollHeight = timeLineRef.current.scrollHeight;
        const clientHeight = timeLineRef.current.clientHeight;

        const node = timeLineRef.current;
        const scrollDistance = (scrollHeight - clientHeight);
        node.scrollTop = percentageRead * scrollDistance / 100;
    }, [percentageRead]);

    return <TimeLine {...{
        extraClassName: 'gc-warande-time-line',
        historicPaper: _db.getWarandeTimelinePaper(),
        passedRef: timeLineRef,
        maxHeight
    }}/> //TODO: reduce first
};

export default WarandeTimeLine;
