import React from 'react';

const Introduction = () => {
    return <section className={'hdw-introduction-section hwt-section container'}>
        <div className={'hdw-content'}>
            <h2>Oudste huis van Leuven?</h2>
            <div className={'hdw-paragraph-wrapper'}>
                <p>
                    Huizen hebben hun geschiedenis. Oude huizen hebben veel geschiedenis. De Warande in de Leuvense
                    Mechselsestraat – vroeger de Borchstraat – is een van de oudste overeind gebleven huizen van de stad
                    – wellicht zelfs het oudste nog ‘normaal’ bewoonde huis van Leuven. Het heeft opstanden, stormen,
                    oorlogen en revoluties overleefd.</p>
                <p>
                    Deze website is opgezet om de geschiedenis van De Warande aan een geïnteresseerd publiek voor te
                    stellen. En om duidelijk te maken dat je in een ‘beschermd monument’ ook anno 2020 comfortabel en
                    gezellig kunt wonen. In de hoop dat er meer soortgelijke woonsten voor verdwijning worden behoed.
                </p>
            </div>
        </div>
    </section>
};

export default Introduction;
