import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Warande from "../Warande/components/Warande";
import RestorationPage from "../Warande/subPages/Restauratie/RestorationPage";

const RoutedApp = () => {
    return <Router>
        <Switch>
            <Route exact path={'/restauratie'}><RestorationPage/></Route>
            <Route exact path="/"><Warande/></Route>
            <Redirect from={'/'} to={'/'}/>
        </Switch>
    </Router>
};

export default RoutedApp;