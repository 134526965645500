class Paper {
	constructor(title, subTitle, description, articles, articleType, rootHeadingNr) {

		const naturalizedHeadingNr = rootHeadingNr && !isNaN(rootHeadingNr) && Math.floor(rootHeadingNr);
		rootHeadingNr = naturalizedHeadingNr > 0 ? naturalizedHeadingNr : 1;

		this.rootHeadingTag = `h${rootHeadingNr}`;

		this.title = title;
		this.subTitle = subTitle;
		this.description = description;
		this.articles = articles;
		this.articleType = articleType;
	}
}

export default Paper;