import React from "react";
import ExtendedRestoration from "./sections/ExtendedRestoration";
import GoBackSection from "../ReturnSection/GoBackSection";
import Section from "../../../components/Sections/Sections";
import ScrollToTopOnMount from "../../components/partials/ScrollToTop/ScrollToTopOnMount";

const RestorationPage = () => {
    return <Section>
        <GoBackSection/>
        <ExtendedRestoration/>
        <GoBackSection/>
        <ScrollToTopOnMount/>
    </Section>
};

export default RestorationPage;