import React from 'react';
import Wrapper from "../../../../components/Containers/Wrapper";
import TYPES from "../../../../CONSTANTS/TYPES";

const HomePageTitle = () => {
    return <>
        <Wrapper {...{type: TYPES.POSITIONED.ABSOLUTE, extraClassName: 'gc-welcome-partial'}}>
            <h1>De Warande</h1>
        </Wrapper>
    </>
};

export default HomePageTitle;
