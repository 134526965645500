import React from 'react';

const HistoryPartial = ({historicPartialRef}) => {
    return <section className={'gc-history-partial'} ref={historicPartialRef}>
        <h2>Uitgebreide geschiedenis</h2>
        <p>Het huis in De Warande - een woning waarvan de oudst bekende geschreven bron een geregistreerde verkoopsakte
            in een Leuvens schepenboek uit 1421 blijkt te zijn – kan doorgaan voor een van de structureel best bewaarde
            in oorsprong
            14-15e eeuwse huizen die de stad nog rijk is. Het huis bevindt zich volgens het Gewestplan als pand met
            perceelnummer 498f in een „Woongebied met culturele, historische en/of esthetische waarde”. Dit tweede
            gedeelte van de huidige Leuvense Mechelsestraat heette in vroeger eeuwen de Borchstraet, de verbindingsweg
            tussen het stadscentrum met zijn marktplaatsen en de burcht.
            Dit grafelijk-hertogelijke kasteel op de Keizersberg werd onder keizer Jozef II gesloopt om in de
            late 19e eeuw vervangen te worden door de neoromaanse benedictijnenabdij.</p>
        <p>De hele buurt kampte decennialang met verloedering. Het verste gedeelte van de Mechelsestraat, richting
            vesten, werd in de 19de eeuw een semi-industrieel gebied met loodsen en opslagplaatsen allerhande van
            diverse Leuvens brouwerijen. Minder geslaagde Britse ‘precisie-bombardementen’ misten op het einde van de
            Tweede Wereldoorlog het rangeerstation en maalden een deel van de wijk, waaronder de abdijkerk van
            Sint-Geertrui en delen van de
            overige abdijgebouwen neer.</p>
        <p>In de onmiddellijke omgeving van De Warande werd een groot aantal van de historische huizen
            die de rampspoed van eeuwen hadden overleefd vanaf de jaren zestig tot zogenaamde „opbrengsthuizen” -
            lees: studentenkoten - omgebouwd en verwaarloosd.</p>
        <p> Een ontploffing gevolgd door brand in drogisterij Thiry, vernielde in april 1967
            enkele van de “Tien Geboden” op de hoek van de Mechelse- en de Halfmaartstraat, schuin tegenover
            De Warande. Met die drie voormalige huurhuizen van de abdij, verdween meteen een van de oudste burgerlijke
            renaissance bouwwerken uit de Nederlanden. Een onoordeelkundige verbouwing van het huis op nr. 141 leidde in
            het najaar van 2000 tot de volledige instorting van het historische pand, waarbij de stadsdiensten uit
            schrik voor nog meer onheil maar het grootste gedeelte van de nog behouden gevel, lieten ommokeren.</p>
        <p>Het tij is langzaam aan het keren. Verscheidene beschermde en nog niet beschermde huizen in de buurt
            werden de voorbije jaren en maanden grondig gerenoveerd, zij het niet altijd naar de volledige zin van
            het agentschap erfgoed. De sinds 1967 braakliggende hoek werd verkocht, met verplichting tot
            reconstructie van de oorspronkelijke bouwvolumes. Het resultaat is niet overtuigend. Het Klein
            Begijnhofproject, dat jarenlang stil lag, is voltooid. AB-Inbev, de opvolger van de Stella-Artois brouwerij,
            trok zich terug uit haar omvangrijke site in en om de Mechelsestraat. De stad werkte voor de hele buurt een
            herwaarderingsproject uit dat anno 2020 nog altijd voluit in uitrol is. De Vismarkt, die twee gedeelten van
            de Mechelsestraat met elkaar verbindt, wordt binnenkort grondig aangepakt. Het gedeelte van de
            Mechelsestraat waarin De Warande ligt, kreeg een opfrisbeurt. De onmiddellijke omgeving van de Sint-Geertrui
            kerk wordt een scharnier tussen de oude Middeleeuwse stad en de geheel nieuwe stadswijken aan de voet van de
            Keizersberg en de vaartkom. De kerk zelf is aan een dringende restauratie toe.</p>
        <p>Het advies van het Bestuur van Monumenten en Landschappen (vandaag het Agentschap Onroerend Erfgoed) op basis
            waarvan De Warande in 1991 het statuut van Beschermd Monument kreeg, beschrijft de voorgevel van het huis
            als “een vier traveeën breed
            barok dwarshuis met twee bouwlagen onder een pannen zadeldak, dat in twee verweerde cartouches
            'anno 1664'
            is gedateerd. Opgetrokken in bak- en natuursteen, met natuurstenen gelijkvloers en verdieping.
            Geheel
            opengewerkte gelijkvloerse verdieping, opgevat als een winkelpui met drie rechthoekige vensters
            met
            doorlopende druiplijst waarboven een rij kleinere vensters waarvan de lateien zijn uitgewerkt
            als een
            geprofileerde puilijst. Barokke rondboogdeur met van blokwerk voorziene posten en zware
            festoenen in de
            zwikken. Gehistorieerde, trapeziumvormige sluitsteen, een hert in een warande of gesloten
            jachtterrein
            voorstellend. Sporen van okerkleurige beschildering. Op de verdieping vier hoge rechthoekige
            vensters
            waarvan de lateien zijn doorgetrokken en die aldus een vooruitspringende druiplijst vormen.
            Gedeeltelijk
            bewaarde, eveneens okergele beschildering van het muurvlak tussen de vensters van het
            gelijkvloers en de
            verdieping, met het bijna onleesbare opschrift: In De Warande Estaminet. In het midden van de
            verdieping een
            bas-reliëf met een putto op een wijnton, een duidelijke verwijzing naar de vroegere bestemming.
            Bekroning
            met een topgevel die het midden houdt tussen een trapgevel en een halsgevel met voluten.
            Opgetrokken in
            baksteen met drukke ritmering met speklagen en bekroond met een driehoekig fronton. Opengewerkt
            met een
            rondboog-laadvenster (met laadbalk) met sluitsteen, stelblokken en druiplijst en drie
            eenvoudiger, vierkante
            zoldervensters.”</p>
        <p>Over de achtergevel zegt het advies: "Eerder sobere afwerking van de bakstenen achtergevel,
            bekroond met
            een topgevel met vlechtingen en schouderstukken. Op de gelijkvloerse verdieping één groot
            venster en een
            rechthoekige deur met gewijzigd bovenlicht, beide in een kalkzandstenen omlijsting van
            negblokken (iets
            grotere stenen te midden van kleinere in de omlijsting van het kozijn). Centraal in de gevel van
            de
            verdieping één groot rechthoekig venster met identieke omlijsting, eveneens verwijzend naar een
            oorspronkelijk kruisvenster. Zolderverdieping opengewerkt met twee ovaalronde oculi in bakstenen
            omlijsting
            en een natuurstenen laadvenster in de top." Over het interieur zegt het advies: "Originele
            structuur met
            moer- en kinderbalken bewaard. Op de gelijkvloerse verdieping één grote ruimte, op de verdieping
            twee kamers
            met elkaar verbonden door middel van een eenvoudige, houten steektrap. Onder de trap toegang tot
            de kelder.
            Dakconstructie met drie genummerde spanten en windas bewaard." Die bevindingen werden eind 2000
            vervolledigd
            door een gedetailleerd materieel technisch vooronderzoek van de voorgevel, uitgevoerd door Johan
            Grootaers
            van A.M. Consult.</p>
    </section>
};

export default HistoryPartial;
