import Paper from "../../../../dataModels/paper";
import article from "../../../../dataModels/article";
import restorationIcon from '../../../assets/icons/restoration.png';
import bronvermeldingIcon from '../../../assets/icons/bronvermelding.png';
import ownershipTransferIcon from '../../../assets/icons/ownership-transfer.png';
import infoIcon from '../../../assets/icons/info-icon.png';
import planImg from '../../../assets/images/oudeplannen/IMG_1964.jpeg';
import IMG_1843 from '../../../assets/images/plans/IMG_1843.jpeg';
import IMG_1905 from '../../../assets/images/plans/IMG_1905.jpg';

import IMG_1841 from '../../../assets/images/plans/IMG_1841.jpg';
import IMG_1910 from '../../../assets/images/plans/IMG_1910.jpeg';


const getHistoryParagraphs = () => '<p>Het huis in De Warande - een woning waarvan de oudst bekende geschreven bron een vermelding in een Leuvense schepenakte uit 1421 blijkt te zijn – kan doorgaan voor een van de structureel best bewaarde in oorsprong 154 eeuwse huizen die de stad nog rijk is. Het huis bevindt zich volgens het Gewestplan als pand met perceelnummer 498f in een „Woongebied met culturele, historische en/of esthetische waarde”. Dit tweede gedeelte van de huidige Leuvense Mechelsestraat heette in vroeger eeuwen de Borchstraet – een verwijzing naar het feit dat ze de verbindingsweg vormde tussen het stadscentrum met zijn marktplaatsen en de burcht, het grafelijk-hertogelijke kasteel op de Caesarsberg, waarvan de laatste zichtbare resten onder keizer Jozef II werden gesloopt om in de late 19e eeuw vervangen te worden door de neoromaanse benedictijnenabdij.' +
    'De hele buurt kampte decennialang met verloedering. Het verste gedeelte van de Mechelsestraat, richting vesten, werd in de 19de eeuw een semi-industrieel gebied met loodsen en opslagplaatsen allerhande van vooral de Stella-brouwerij. Minder geslaagde Britse ‘precisie-bombardementen’ misten op het einde van de Tweede Wereldoorlog het rangeerstation en maaiden een deel van de wijk, waaronder de kerk en delen van de abdij weg.  In de onmiddellijke omgeving van De Warande werd een groot aantal van de historische huizen die de rampspoed van eeuwen hadden overleefd vanaf de jaren zestig tot zogenaamde „opbrengsthuizen” - lees: studentenkoten - omgebouwd en verwaarloosd. Een ontploffing, gevolgd door brand, vernielde in april 1967 enkele van de “Tien Geboden” op de hoek van de Mechelse- en de Halfmaartstraat, schuin tegenover „De Warande”. Een onoordeelkundige verbouwing van het huis op nr. 141 leidde in het najaar van 2000 tot de volledige instorting van het historische pand, waarbij de stadsdiensten uit schrik voor nog meer onheil maar het grootste gedeelte van de nog behouden gevel, lieten ommokeren.' +
    'Het tij is langzaam aan het keren. Verscheidene geklasseerde en nog niet geklasseerde huizen werden de voorbije jaren en maanden grondig gerenoveerd, zij het niet altijd naar de volledige zin van het Agentschap Onroerend Erfgoed. De sinds 1967 braakliggende hoek werd verkocht, met verplichting tot reconstructie van de oorspronkelijke bouwvolumes. Het resultaat is niet echt overtuigend. Het Klein Begijnhofproject, dat jarenlang stil lag, komt weer schot. Interbrew, de opvolger van de Stella-Artois brouwerij, gaf de site in en om de Mechelsestraat op, en de stad werkte voor de hele buurt een herwaarderingsproject uit dat anno 2020 nog altijd voluit in uitrol is. De Vismarkt, die twee gedeelten van de Mechelsestraat (de vroegere Borchstraete en de Schipsstraat) met elkaar verbindt, wordt binnenkort grondig aangepakt. Het gedeelte van de Mechelsestraat waarin De Warande ligt, krijgt nu al een opfrisbeurt.\n' +
    'Het advies van het Bestuur van Monumenten en Landschappen (BML) op basis waarvan De Warande in 1991 het statuut van Beschermd Monument kreeg, beschrijft de voorgevel van het huis als “een vier traveeën breed barok dwarshuis met twee bouwlagen onder een pannen zadeldak, dat in twee verweerde cartouches \'anno 1664\' is gedateerd. Opgetrokken in bak- en natuursteen, met natuurstenen gelijkvloers en verdieping. Geheel opengewerkte gelijkvloerse verdieping, opgevat als een winkelpui met drie rechthoekige vensters met doorlopende druiplijst waarboven een rij kleinere vensters waarvan de lateien zijn uitgewerkt als een geprofileerde puilijst. Barokke rondboogdeur met van blokwerk voorziene posten en zware festoenen in de zwikken. Gehistorieerde, trapeziumvormige sluitsteen, een hert in een warande of gesloten jachtterrein voorstellend. Sporen van okerkleurige beschildering. Op de verdieping vier hoge rechthoekige vensters waarvan de lateien zijn doorgetrokken en die aldus een vooruitspringende druiplijst vormen. Gedeeltelijk bewaarde, eveneens okergele beschildering van het muurvlak tussen de vensters van het gelijkvloers en de verdieping, met het bijna onleesbare opschrift: In De Warande Estaminet. In het midden van de verdieping een bas-reliëf met een putto op een wijnton, een duidelijke verwijzing naar de vroegere bestemming. Bekroning met een topgevel die het midden houdt tussen een trapgevel en een halsgevel met voluten. Opgetrokken in baksteen met drukke ritmering met speklagen en bekroond met een driehoekig fronton. Opengewerkt met een rondboog-laadvenster (met laadbalk) met sluitsteen, stelblokken en druiplijst en drie eenvoudiger, vierkante zoldervensters.”\n' +
    'Over de achtergevel zegt het BML-advies: ,,Eerder sobere afwerking van de bakstenen achtergevel, bekroond met een topgevel met vlechtingen en schouderstukken. Op de gelijkvloerse verdieping één groot venster en een rechthoekige deur met gewijzigd bovenlicht, beide in een kalkzandstenen omlijsting van negblokken (iets grotere stenen te midden van kleinere in de omlijsting van het kozijn). Centraal in de gevel van de verdieping één groot rechthoekig venster met identieke omlijsting, eveneens verwijzend naar een oorspronkelijk kruisvenster. Zolderverdieping opengewerkt met twee ovaalronde oculi in bakstenen omlijsting en een natuurstenen laadvenster in de top.” Over het interieur zegt het advies: „Originele structuur met moer- en kinderbalken bewaard. Op de gelijkvloerse verdieping één grote ruimte, op de verdieping twee kamers met elkaar verbonden door middel van een eenvoudige, houten steektrap. Onder de trap toegang tot de kelder. Dakconstructie met drie genummerde spanten en windas bewaard.” Die bevindingen werden eind 2000 vervolledigd door een gedetailleerd materieel technisch vooronderzoek van de voorgevel, uitgevoerd door Johan Grootaers van A.M. Consult.</p>';

const mockTimeLineArticles = [
    {
        ...new article('1421', 'Schepenakte Leuven', '<p>Jan Hollandere verkoopt De Warande aan meester Jan Baten, zoon van  Jan Baeten, steenhouwer, huis in de Borchstraat, tussen het goed van Jan Vullinc en Hendrik van Artslo, genaamd Cauwelille, bakker</p>', {src: ownershipTransferIcon}),
        date: new Date(1421, 5, 17)
    },
    {
        ...new article('1465', null, '<p>Elisabeth van Espen, weduwe van Radulphus Rabode, verkoopt De Warande aan Wilhelmus Hubrechts en echtgenote Katarina Boschmans. Hubrechts was tussen 1472 en 1498 12 maal deken van de natie van de \'fruiteniers\'</p>', {src: ownershipTransferIcon}),
        date: new Date(1465)
    },
    {
        ...new article('1470 - 1512', null, '<p>uit het Register Biercijns: De Warande bewoond door Johannes van Boemale, brouwer</p>', {src: bronvermeldingIcon}),
        date: new Date(1470)
    },
    /*{
        ...new article('1522', null, '<p>De Warande wordt vermeld in het Moldersboeck</p>', {src: bronvermeldingIcon}),
        date: new Date('1522')
    },
    {
        ...new article('1534', null, '<p>Schepenboek vermeldt Claes van de Putte, wonend in het huis geheeten De Warande</p>', {src: bronvermeldingIcon}),
        date: new Date('1534')
    },
    {
        ...new article('1545', null, '<p>Schepenboek vermeldt Pieter van Caverson, die in De Warande brouwt</p>', {src: bronvermeldingIcon}),
        date: new Date('1545')
    },*/
    {
        ...new article('1614', null, '<p>Sr. Peeter van Caverson, ‘sone wijlen Hectors’, kleinzoon van Peeter wijlen van Caverson en Ursulle Wittemans, huurt De Warande van Joanna Crols, weduwe Cielis van Doirme. Peeter betaalt de weduwe eene rente van vierthien carolus guldens – dit betekent dat de Caversons zowat een eeuw in De Warande hebben gebrouwen</p>', {src: infoIcon}),
        date: new Date(1614)
    },
    {
        ...new article('1630', null, '<p>De Warande is eigendom van Peeter Stroobant, licentiaat in de medicijnen, man en momboir van Elizabeth Crabbeels. In erfpacht aan Aert Goris en Adriana de Vleeschouwer</p>', {src: infoIcon}),
        date: new Date(1630)
    },
    {
        ...new article('1664', 'Verbouwing', '<p>Grondige verbouwing gevel</p>', {src: restorationIcon}),
        date: new Date(1664)
    },
    {
        ...new article('1670', null, '<p>Eigenaar is nog altijd de weduwe Aert Gooris, Adriana de Vleeschouwer</p>', {src: infoIcon}),
        date: new Date(1670)
    },
    {
        ...new article('1675', null, '<p>Wijkboek: eigenaar van De Warande zijn inmiddels de erven Guilliam Crabbe</p>', {src: bronvermeldingIcon}),
        date: new Date(1675)
    },
    {
        ...new article('1700', null, '<p>Uit een akte blijkt dat Joanna Crabbe, weduwe van Daniel van Gobbelschroye, en haar broer Arnoldus Crabbe, 400 gulden lenen met ‘De Warande’ als onderpand</p>', {src: bronvermeldingIcon}),
        date: new Date(1700)
    },
    {
        ...new article('1710', null, '<p>In De Warande brouwt op dat ogenblik Jan-Baptiste Culens. Die was ‘ingetrouwd’ door een huwelijk met Maria Crabbe, de dochter van Arnoldus. Uit hun huwelijk komen voort Maria Culens en Livius Culens. Maria Culens huwt met Guilliemus of Guilliam Hannaert.</p>', {src: infoIcon}),
        date: new Date(1710)
    },
    {
        ...new article('1726', null, '<p>Register Biercijns: De Warande wordt bewoond door bovengenoemde Guilliam Hannaert,. Hannaert wordt in 1737 vermeld als ‘overdeken’ van het brouwersambacht. Die ‘brouwersnatie’ draagt hem tussen 1729 en 1742, met uitzondering van het jaar 1739, keer op keer voor als kandidaat-schepen van de stad</p>', {src: infoIcon}),
        date: new Date(1726)
    },
    {
        ...new article('1727', null, '<p>Register Biercijns: in De Warande brouwt inmiddels de broer van Maria Culens en schoonbroer van Guiliam Hannaert, Livinus Culens, getrouwd met Catharina Panis (of Panes). Livinus had ‘De Warande’ geërfd van zijn moeder Maria Crabbe, samen met zijn zuster Maria, die hij had ‘uitgekocht’.  </p>', {src: infoIcon}),
        date: new Date(1727)
    },
    {
        ...new article('1769', null, '<p>De erven Livinus Culens verkopen De Warande via openbare verkoop aan Jan-Baptist Laeremans, gehuwd met Joanna, alias Anna Hendrickx. Jan Laeremans was net als Livinus Culens brouwer en ‘leermeester-brouwer’.</p>', {src: ownershipTransferIcon}),
        date: new Date(1769)
    },
    {
        ...new article('1815', null, '<div>' +
            `<p>Landmeter Wirix maakt op  28 februari een plan op van de Warande en de twee belendende panden voor de uitvoering van het testament van het kinderloos overleden ` +
            `echtpaar Laeremans-Hendrickx. <img alt="" src="${planImg}"/> Op 18 maart wordt De Warande verkocht aan Jean Baptiste Van De Put, ` +
            'cabaretier en handelaar in granen.</p>' +
            '</div>', {src: infoIcon}),
        date: new Date(1815)
    },
    {
        ...new article('1841', null, `<p>Van De Put is in 1839 overleden. De Warande wordt openbaar verkocht aan brouwersgast Hendricus Mingelinckx. Bouwmeester Verheyden maakt daartoe op 22 februari een plan van het huis op:</p><img alt="" src="${IMG_1841}"/>`, {src: ownershipTransferIcon}),
        date: new Date(1841)
    },
    {
        ...new article('1843', null, `<p>Op 15 juni verkoopt Mingelinckx aan Petrus Guillielmus-Jacobus Janssens, meester-kuiper en herbergier. In hetzelfde jaar wordt de voorgevel verbouwd en verdwijnen op de eerste etage de kruisvensters. Er wordt ook een nieuw bouwplan opgesteld: <img alt="" src="${IMG_1843}"/></p>`, {src: ownershipTransferIcon}),
        date: new Date(1843)
    },
    {
        ...new article('1848', null, '<p>Het stapelhuis naast De Warande richting de Keizersberg wordt verbouwd met alleen de bovenverdiepingen op de rooilijn van 1819. Veel huizen in Leuven krijgen in die tijd een tijdelijke uitzondering op de regel om de nieuwe "Hollandse" rooilijnen te respecteren. In afwachting van een algemene regeling, mag een gelijkvloerse verdieping op de oude rooilijn opgetrokken worden.</p>', {src: infoIcon}),
        date: new Date(1848)
    },
    {
        ...new article('1880', null, '<p>Jozefus Boogaerts, hovenier, koopt De Warande over van herbergier Surings die vijf jaar eerder eigenaar was geworden.</p>', {src: ownershipTransferIcon}),
        date: new Date(1880)
    },
    {
        ...new article('1905', null, `<p>Karel Saelemaekers, smid, koopt in maart De Warande. Hij verbouwt het achterhuis tot een smidse en sterft in \'56. Een plan:</p> <img alt="" src="${IMG_1905}"/>`, {src: ownershipTransferIcon}),
        date: new Date(1905)
    },
    {
        ...new article('1910', null, `<p>Plan nieuwe aanpassingen:</p><img alt="" src="${IMG_1910}">`, {src: infoIcon}),
        date: new Date(1910)
    },
    {
        ...new article('1988', null, '<p>Kunsthistoricus professor dr. Vandenbroeck en Mariana Starcevic kopen op 27 september de sterk vervallen Warande van Clement Saelemaekers, zoon van Karel Saelemaekers.</p>', {src: ownershipTransferIcon}),
        date: new Date(1988)
    },
    {
        ...new article('1989', null, '<p>De Warande wordt door toedoen van Vandenbroeck op 2 oktober 1989 op het voorontwerp van lijst van voor bescherming vatbare monumenten geplaatst.</p>', {src: infoIcon}),
        date: new Date(1989)
    },
    {
        ...new article('Eind jaren \'80', null, '<p>Een nieuwe eigenaar, kunsthistoricus Paul Vandenbroeck, laat een aantal dringende herstellingswerken uitvoeren.</p>', {src: restorationIcon}),
        date: new Date(1990)
    },
    {
        ...new article('1991', null, '<p>Op 22 maart 1991 wordt het huis door toenmalig minister Louis Waltniel als monument beschermd, “om reden van artistieke en historische waarde".</p>', {src: infoIcon}),
        date: new Date(1991)
    },
    {
        ...new article('1999', null, '<p>Op 23 augustus 1999 komt De Warande in het bezit van Elisabeth Houben en Axel Buyse, met de bedoeling om het nog grotendeels bouwvallige huis verder te restaureren en er hun woonhuis van te maken.</p>', {src: restorationIcon}),
        date: new Date(1999)
    },
    {
        ...new article('2006', null, '<p>Elisabeth Houben en Axel Buyse nemen hun intrek in de opnieuw bewoonbare Warande</p>', {src: infoIcon}),
        date: new Date(2006)
    }
    ,
    {
        ...new article('2007', null, '<p>De Warande wint de monumentenprijs van Vlaams-Brabant en komt als tweede in het concours van de Vlaamse monumentenprijs.</p>', {src: infoIcon}),
        date: new Date(2007)
    }

];
const getWarandeTimelinePaper = () => new Paper('Warande tijdlijn', null, null, mockTimeLineArticles);

export default {
    getHistoryParagraphs,
    getWarandeTimelinePaper
};
