import React from 'react';
import Section from "../../components/Sections/Sections";
import WelcomeSection from "./secctions/WelcomeSection/WelcomeSection";
import HistorySection from "./secctions/HistorySection/HistorySection";
import OldestHouse from "./secctions/OldestHouseSection/OldestHouse";
import DuitsePropaganda from "./secctions/NaziPropaganda/DuitsePropaganda";
import Restauratie from "./secctions/Restauratie/Restauratie";
import Introduction from "./secctions/Introduction/Introduction";
import CurrentState from "./secctions/CurrentState/CurrentState";
import Credits from "./secctions/Credits/Credits";

import {useInView} from 'react-intersection-observer';

const Warande = () => {

    const {ref: restorationRef, inView: isRestorationSectionInViewport} = useInView({threshold: 0.3});
    const {ref: currentStateRef, inView: isCurrentStateSectionInViewport} = useInView({threshold: 0.3});

    return <Section>
        <WelcomeSection/>
        <Introduction/>
        <OldestHouse/>
        <DuitsePropaganda/>
        <Restauratie {...{isInViewPort: isRestorationSectionInViewport, domRef: restorationRef}}/>
        <CurrentState {...{isInViewPort: isCurrentStateSectionInViewport, domRef: currentStateRef}}/>
        <HistorySection/>
        <Credits/>
    </Section>
};

export default Warande;
